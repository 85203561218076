//引入axios请求方法
import { getMenuListApi } from '../api/meau'
export default {
    state: {
        //当前选中的选项卡
        editableTabsValue: "roleList",
        //选项卡数据域
        editableTabs: [{
            title: '优惠券',
            name: 'roleList'
        }],
        //菜单伸缩控制
        isCollapse: false,
        //菜单数据
        menuList: [],
        //路由数据
        routerList: []
    },
    mutations: {
        //获取tabs
        getTabs(state) {
            let tabs = sessionStorage.getItem('tabsList')
            if (tabs) {
                state.editableTabs = JSON.parse(tabs)
            }
        },
        //设置当前激活的选项卡
        setActiveTabs(state, val) {
            state.editableTabsValue = val;
        },
        // 菜单栏展开收缩
        setOpenOrClose(state) {
            state.isCollapse = !state.isCollapse;
        },
        clickMenu(state, val) {
            //1.判断当前点中菜单是否在tabs里面
            let res = state.editableTabs.findIndex(item => item.name === val.name)
                //2.如果不存在，组织tabs数据，存放到editableTabs
            if (res === -1) {
                let tab = {};
                tab.title = val.label;
                tab.name = val.name;
                state.editableTabs.push(tab);
            }
            //3.设置当前选中的选项卡,
            state.editableTabsValue = val.name;
            //4.把选中的选项卡存放到sessionStorage里面
            sessionStorage.setItem('tabsList', JSON.stringify(state.editableTabs))
        },
        //设置菜单数据
        setMenuList(state, list) {
            state.menuList = list;
        },
        //设置路由数据
        setRouterList(state, list) {
            state.routerList = list;
        },
        // //生成动态路由
        // dynamicRouter(state, router) {
        //     //获取原来的路由
        //     let oldRouter = router.options.routes;
        //     //获取后台生成的路由
        //     let routerList = state.routerList;
        //     routerList.forEach(item => {
        //         //html页面，需要IFrame展示
        //         if (/^.+\.html$/ig.test(item.url)) {
        //             item.component = resolve => require([`@/views/IFrame/IFrame.vue`], resolve)
        //         } else {
        //             item.component = resolve => require([`@/views${item.url}.vue`], resolve);
        //         }
        //         oldRouter[1].children.push(item);
        //     })
        //     router.addRoutes(oldRouter)
        //         // console.log(router, "")
        // }
    },
    actions: {
        async getMenuList({ commit }) {
            // console.log(router, "router")
            let res = await getMenuListApi();
            if(res.data.code=="000"){
                commit('setMenuList',res.data.result);
            }
            //菜单数据
            // let menuList = res.data.data.menuList;
            // console.log(menuList, 'menuListmenuListmenuListmenuList')
            //路由数据
            // let routerList = res.data.data.routerList;
            // console.log(routerList, 'routerListrouterListrouterListrouterList')
            //权限字段
            // sessionStorage.setItem('codeList', JSON.stringify(res.data.data.authList))
                //把获取到的菜单或路由数据存放到vuex里面
            // commit('setMenuList', menuList);
            // commit('setRouterList', routerList);
            // //生成动态路由
            // commit('dynamicRouter', router)
        }
    }
}