<template>
  <el-menu
    :default-active="$route.path"
    class="el-menu-vertical-demo"
    :collapse="isCollapse"
    unique-opened
  >
    <menu-item :menuList="menuList"></menu-item>
  </el-menu>
</template>

<script>
import MenuItem from "./MenuItem";
import { mapState } from "vuex";
export default {
  components: { MenuItem },
  computed: {
    ...mapState({
      //菜单伸缩
      isCollapse: (state) => state.MenuStore.isCollapse,
      //菜单数据
      menuList: state => state.MenuStore.menuList
    }),
  },
  data() {
    return {
      // menuList: [
      //   {
      //     children: [
      //       {
      //         children: [],
      //         code: "sys:dept",
      //         icon: "el-icon-copy-document",
      //         id: 33,
      //         isHome: 0,
      //         label: "推送通知发送",
      //         name: "departmentList",
      //         orderNum: 2,
      //         parentId: 17,
      //         path: "/departmentList",
      //         remark: "推送通知发送",
      //         type: "1",
      //         url: "/system/Department/DepartmentList",
      //       },
      //       {
      //         children: [],
      //         code: "sys:user",
      //         icon: "el-icon-s-custom",
      //         id: 18,
      //         isHome: 0,
      //         label: "聊天记录审核",
      //         name: "goodCategory",
      //         orderNum: 3,
      //         parentId: 17,
      //         path: "/goodCategory",
      //         type: "1",
      //         url: "/goods/goodsCategory/goodsCategoryList",
      //       },
      //       {
      //         children: [],
      //         code: "sys:role",
      //         icon: "el-icon-rank",
      //         id: 23,
      //         isHome: 0,
      //         label: "投诉建议处理",
      //         name: "roleList",
      //         orderNum: 4,
      //         parentId: 17,
      //         path: "/roleList",
      //         type: "1",
      //         url: "/system/Role/RoleList",
      //       },
      //       {
      //         children: [],
      //         code: "sys:menu",
      //         icon: "el-icon-menu",
      //         id: 28,
      //         isHome: 0,
      //         label: "注销用户处理",
      //         name: "menuList",
      //         orderNum: 5,
      //         parentId: 17,
      //         path: "/menuList",
      //         type: "1",
      //         url: "/system/Menu/MenuList",
      //       },
      //     ],
      //     code: "sys:manage",
      //     icon: "el-icon-document",
      //     id: 17,
      //     isHome: 0,
      //     label: "系统管理",
      //     name: "system",
      //     orderNum: 1,
      //     parentId: 0,
      //     path: "/system",
      //     type: "0",
      //     url: "/system/system",
      //   },
      //   {
      //     children: [
      //       {
      //         children: [],
      //         code: "sys:goodsCategory",
      //         icon: "el-icon-s-data",
      //         id: 36,
      //         isHome: 0,
      //         label: "用户数据展示",
      //         name: "userList",
      //         orderNum: 1,
      //         parentId: 34,
      //         path: "/userList",
      //         type: "1",
      //         url: "/system/User/goodsCategory/UserList",
      //       },
      //     ],
      //     code: "sys:goods",
      //     icon: "el-icon-picture",
      //     id: 34,
      //     isHome: 0,
      //     label: "用户管理",
      //     name: "",
      //     orderNum: 2,
      //     parentId: 0,
      //     path: "/goods",
      //     type: "0",
      //   },
      //   {
      //     children: [
      //       {
      //         children: [],
      //         code: "sys:document",
      //         icon: "el-icon-s-operation",
      //         id: 77,
      //         isHome: 0,
      //         label: "倾听者数据展示",
      //         name: "iframe",
      //         orderNum: 0,
      //         parentId: 42,
      //         path: "/iframe",
      //         type: "1",
      //         url: "/IFrame/IFrame",
      //       },
      //       {
      //         children: [],
      //         code: "sys:systemCode",
      //         icon: "el-icon-files",
      //         id: 43,
      //         isHome: 0,
      //         label: "倾听者入驻",
      //         name: "systemCode",
      //         orderNum: 0,
      //         parentId: 42,
      //         path: "/systemCode",
      //         type: "1",
      //         url: "/system/config/code",
      //       },
      //     ],
      //     code: "sys:systenConfig",
      //     icon: "el-icon-receiving",
      //     id: 42,
      //     isHome: 0,
      //     label: "倾听者管理",
      //     name: "systenConfig",
      //     orderNum: 3,
      //     parentId: 0,
      //     path: "/systenConfig",
      //     type: "0",
      //     url: "/system/systenConfig",
      //   },
      //   {
      //     children: [
      //       {
      //         children: [],
      //         code: "sys:document",
      //         icon: "el-icon-s-operation",
      //         id: 77,
      //         isHome: 0,
      //         label: "专家数据展示",
      //         name: "speciameant",
      //         orderNum: 0,
      //         parentId: 42,
      //         path: "/speciameant",
      //         type: "1",
      //         url: "/system/specialist/speciameant",
      //       },
      //       {
      //         children: [],
      //         code: "sys:systemCode",
      //         icon: "el-icon-files",
      //         id: 43,
      //         isHome: 0,
      //         label: "专家审核入驻",
      //         name: "expertreview",
      //         orderNum: 0,
      //         parentId: 42,
      //         path: "/expertreview",
      //         type: "1",
      //         url: "/system/specialist/expertreview",
      //       },
      //     ],
      //     code: "sys:systenConfig",
      //     icon: "el-icon-receiving",
      //     id: 42,
      //     isHome: 0,
      //     label: "专家管理",
      //     name: "specialist",
      //     orderNum: 3,
      //     parentId: 0,
      //     path: "/specialist",
      //     type: "0",
      //     url: "/system/specialist",
      //   },
      //   {
      //     children: [
      //       {
      //         children: [],
      //         code: "sys:document",
      //         icon: "el-icon-s-operation",
      //         id: 77,
      //         isHome: 0,
      //         label: "推广收益",
      //         name: "popularize",
      //         orderNum: 0,
      //         parentId: 42,
      //         path: "/popularize",
      //         type: "1",
      //         url: "/system/other/popularize",
      //       },
      //       {
      //         children: [],
      //         code: "sys:document",
      //         icon: "el-icon-s-operation",
      //         id: 77,
      //         isHome: 0,
      //         label: "提现申请",
      //         name: "withdrawal",
      //         orderNum: 0,
      //         parentId: 42,
      //         path: "/withdrawal",
      //         type: "1",
      //         url: "/system/other/withdrawal",
      //       },
      //       {
      //         children: [],
      //         code: "sys:document",
      //         icon: "el-icon-s-operation",
      //         id: 77,
      //         isHome: 0,
      //         label: "平台订单",
      //         name: "order",
      //         orderNum: 0,
      //         parentId: 42,
      //         path: "/order",
      //         type: "1",
      //         url: "/system/other/order",
      //       },
      //       {
      //         children: [],
      //         code: "sys:systemCode",
      //         icon: "el-icon-files",
      //         id: 43,
      //         isHome: 0,
      //         label: "平台数据",
      //         name: "formdata",
      //         orderNum: 0,
      //         parentId: 42,
      //         path: "/formdata",
      //         type: "1",
      //         url: "/system/other/formdata",
      //       },
      //     ],
      //     code: "sys:systenConfig",
      //     icon: "el-icon-receiving",
      //     id: 42,
      //     isHome: 0,
      //     label: "其他功能",
      //     name: "other",
      //     orderNum: 3,
      //     parentId: 0,
      //     path: "/other",
      //     type: "0",
      //     url: "/system/other",
      //   },
      // ],
    };
  },
};
</script>

<style lang="scss" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.el-menu {
  border-right: none !important;
}
</style>