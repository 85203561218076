import Cookies from 'js-cookie'
//定义token的key
const tokenKey = 'token';
//定义用户id的key
const userIdKey = 'userId';
//设置token到cookies里面
export function setToken(params) {
    return Cookies.set(tokenKey, params)
}
//获取token
export function getToken() {
    return Cookies.get(tokenKey)
}
//移除token
export function removeToken() {
    return Cookies.remove(tokenKey)
}
//设置用户id到cookies里面
export function setUserId(params) {
    return Cookies.set(userIdKey, params)
}
//获取用户id
export function getUserId() {
    return Cookies.get(userIdKey)
}
//移除用户id
export function removeUserId() {
    return Cookies.remove(userIdKey)
}
//清除sessionStorage数据
export function cleanStorage() {
    return sessionStorage.clear()
}