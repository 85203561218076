import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
//下面代码用于解决 
//Redirected when going from "/departmentList" to "/userList" via a navigation
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/message',
  //   name: 'message',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/test/Message.vue')
  // },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import( /* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    redirect: "/roleList",
    children: [
    {
      path: '/departmentList',
      name: 'departmentList',
      component: () => import(/* webpackChunkName: "about" */ '@/views/system/department/department.vue')
    },
    {
      path: '/userList',
      name: 'userList',
      component: () => import(/* webpackChunkName: "about" */ '@/views/system/User/UserList.vue')
    },
    {
      path: '/roleList',
      name: 'roleList',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/system/Role/RoleList.vue')
    },
    {
      path: '/menuList',
      name: 'menuList',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/system/Menu/MenuList.vue')
    },
    {
      path: '/goodCategory',
      name: 'goodCategory',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/goods/goodsCategory/goodsCategoryList.vue')
    },
    {
      path: '/goodsBrand',
      name: 'goodsBrand',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/goods/goodsBrand/goodsBrandList.vue')
    },
    {
      path: '/systemCode',
      name: 'systemCode',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/system/config/code.vue')
    },
    {
      path: '/document',
      name: 'document',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/system/config/systemDocument.vue')
    },
    {
      path: '/iframe',
      name: 'iframe',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/IFrame/IFrame.vue')
    },
    {
      path: '/speciameant',
      name: 'speciameant',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/system/specialist/speciameant.vue')
    },
    {
      path: '/expertreview',
      name: 'expertreview',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/system/specialist/expertreview.vue')
    },
    {
      path: '/popularize',
      name: 'popularize',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/system/other/popularize.vue')
    }, {
      path: '/withdrawal',
      name: 'withdrawal',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/system/other/withdrawal.vue')
    }, {
      path: '/order',
      name: 'order',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/system/other/order.vue')
    }, {
      path: '/formdata',
      name: 'formdata',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/system/other/formdata.vue')
    }, {
      path: '/specialist',
      name: 'specialist',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '@/views/system/specialist.vue')
    },
    
    ]
  },
  {
    path: '/dialog',
    name: 'dialog',
    component: () =>
      import( /* webpackChunkName: "about" */ '../views/test/dialog.vue')
  },
  // {
  //     path: '*',
  //     name: '/404',
  //     component: () =>
  //         import ( /* webpackChunkName: "about" */ '../views/404.vue')
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router