import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'; //引入element组件库
import 'element-ui/lib/theme-chalk/index.css'; //引入element样式文件
Vue.use(ElementUI); //使用element
// Vue.config.productionTip = false
// import Cookies from "js-cookie";
import { getToken, removeToken, removeUserId } from '../src/utils/auth'
//解决菜单收缩时文字影藏不完整的问题
import Fragment from 'vue-fragment'
//引入阿里图标
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import '../src/assets/icon/iconfont.css'
// //引入清空表单的工具
import resetForm from '../src/utils/resetForm'
Vue.prototype.$resetForm = resetForm;
// //快速复制对象的值
import objCoppy from '../src/utils/objCoppy'
Vue.prototype.$objCoppy = objCoppy;
//按钮权限判断
import hasPermisson from '../src/pesmisson/index'
Vue.prototype.hasPerm = hasPermisson
    //信息提示
    // 引入mock.js
    // import ('./mock/index')
import MessageUtils from '../src/utils/MessageUtils'
Vue.prototype.$message = MessageUtils
Vue.use(Fragment.Plugin)
    //白名单配置
const whiteList = ['/login'];
router.beforeEach(async(to, from, next) => {
    NProgress.start()
        // console.log(to, 'to')
    console.log(from, 'from')
        // console.log(next)
        //从cookies里面获取token
    let token = getToken();
    if (token) { //token存在 
        if (to.path === "/login" || to.path === "/") {
            next({ path: '/home' })
            NProgress.done()
        } else {
            console.log(store.state)
            let menuList = store.state.MenuStore.menuList;
            if (menuList && menuList.length > 0) {
                next();
                NProgress.done()
            } else {
                try {
                    //延时一下，防止报错,真实上线，不需要mock.js，所以就不需要延时函数包裹
                    setTimeout(async function() {
                        await store.dispatch("getMenuList", router)
                    }, 70);
                    next();
                    NProgress.done()
                } catch (error) {
                    //重置token
                    // Cookies.remove("token")
                    removeToken();
                    //重置用户id
                    removeUserId();
                    //跳到登录
                    next({ path: '/login' })
                    NProgress.done()
                }
            }
        }
        //获取tabs
        store.commit('getTabs')
            //设置当前选中的tabs
        store.commit('setActiveTabs', to.name)
    } else { //token不存在
        //判断是否存在白名单中
        if (whiteList.indexOf(to.path) !== -1) { //存在白名单中
            next();
            NProgress.done()
        } else { //不存在白名单中,去登录
            next({ path: '/login' })
            NProgress.done()
        }
    }
})
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')