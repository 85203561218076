<template>
  <fragment>
    <template v-for="menu in menuList">
      <el-submenu
        v-if="menu.children && menu.children.length > 0"
        :index="menu.path"
        :key="menu.index"
      >
        <template slot="title">
          <i class="el-icon-receiving"></i>
          <span style='font-size:15px;font-weight:600;' slot="title">{{ menu.label }}</span>
        </template>
        <menu-item :menuList="menu.children"></menu-item>
      </el-submenu>
      <el-menu-item @click="clickMenu(menu)" v-else :index="menu.path" :key="menu.index">
        <i class="el-icon-s-operation"></i>
        <span slot="title">{{ menu.label }}</span>
      </el-menu-item>
    </template>
  </fragment>
</template>

<script>
// import MenuItem from "./MenuItem";
export default {
  name: "MenuItem",
  props:{
    menuList:{
      type:Array,
      defaults:()=>{
      }
    }
  },
  // components: {
  //   MenuItem,
  // },
  methods:{
    clickMenu(item){
      //1.把当前点中的菜单节点放到tabs
      this.$store.commit('clickMenu',item)
      //2.跳转路由
       this.$router.push({name:item.name})
    }
  }
};
</script>

<style lang="scss" scoped>
</style>